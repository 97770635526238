import config from '../config';

const requestHandleAPI = async (bearerToken, endpoint, method, requestBody) => {
    const requestHeaders = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearerToken}`,
    }
    const requestOptions = {
        method: method,
        headers: requestHeaders,
    };

    if (!(method=='HEAD' || method=='GET')) {
        requestOptions.body = JSON.stringify(requestBody);
    }

    const response = fetch(`${config.HANDLE.API_URL}/${endpoint}`, requestOptions)
        .then(response => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            return error;
        });

    return await response
}

export const putHandles = async (bearerToken, item) => {
    return await requestHandleAPI(bearerToken, "handles", 'PUT', item);
}

export const getHandles = async (bearerToken) => {
    return await requestHandleAPI(bearerToken, "handles", 'GET', null);
}
