import React, { useState } from "react";
import {
    Button,
    useToast,
} from "@chakra-ui/react";
import { useAuthContext } from "../../contexts/AuthContext";
import ConfirmationModal from "../modals/confirmation";
import { deleteAccount, logout } from '../../utils/auth';

const DeleteAccount = () => {
    const toast = useToast();
    const { currentUser, setCurrentUser } = useAuthContext();
    const [ deleteUserConfirmation, setDeleteUserConfirmation ] = useState();
    
    const deleteAccountAction = () => {
        if (!deleteAccount(currentUser)) {
            toast({
                title: "Account Deletion Failed",
                description: "Please try again later.",
                status: "error",
                duration: 5000,
                isClosable: true,
            })
            return
        } else {
            logout();
        }
        window.location.href = '/splash';
    }

    return (
        <>
            <Button
            onClick={() => {
                setDeleteUserConfirmation(true)
            }}
            marginTop='3'
            size="md"
            bg={'red.400'}
            color={'white'}
            _hover={{
                bg: 'red.500',
            }}>
                Delete Account
            </Button>
            <ConfirmationModal 
                isOpen={deleteUserConfirmation} 
                setIsOpen={setDeleteUserConfirmation} 
                confirmAction={deleteAccountAction} 
                title='Delete Account' 
                description='Are you sure you want to delete your account? This action cannot be undone.'
            />
        </>
    );
}

export default DeleteAccount;