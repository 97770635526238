import {
    Button,
} from "@chakra-ui/react";
import { logout } from '../../utils/auth';

const SignOut = () => {
    return (
        <Button
        onClick={() => {
            logout();
            window.location.href = '/splash';
        }}
        marginTop='3'
        size="md"
        bg={'blue.400'}
        color={'white'}
        _hover={{
            bg: 'blue.500',
        }}>
            Sign Out
        </Button>
    );
}

export default SignOut;