import { Box, SimpleGrid, Icon, Text, Stack, Flex } from '@chakra-ui/react'
import { FcAdvertising, FcPaid, FcList } from 'react-icons/fc'

const EasySteps = () => {
    return (
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
            <Feature
            icon={<Icon as={FcPaid} w={10} h={10} />}
            title={'Sign up'}
            text={
                'Sign up in ten seconds or less'
            }
            />
            <Feature
            icon={<Icon as={FcList} w={10} h={10} />}
            title={'List Handles'}
            text={
                'List the handles you want'
            }
            />
            <Feature
            icon={<Icon as={FcAdvertising} w={10} h={10} />}
            title={'Get Notified'}
            text={
                'We notify you when the handle becomes available'
            }
            />
        </SimpleGrid>
    )
}

const Feature = ({ title, text, icon }) => {
  return (
    <Stack>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 'full',
        w: 12,
        h: 12,
        bg: 'gray.100',
      
      }}>
        <div>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 'full',
            textAlign: 'center',
          }}>
            {icon}
          </div>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 'full',
            textAlign: 'center',
          }}>
            <b>{title}</b>
          </div>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 'full',
            textAlign: 'center',
          }}>
            {text}
          </div>
        </div>
      </div>
    </Stack>
  )
}

export default EasySteps;