const config = {
  development: {
    AWS: {
      REGION: 'us-east-1',
      USER_POOL_ID: 'us-east-1_s7jtrQXVA',
      USER_POOL_CLIENT_ID: 'no8inoq9jslbts48f4v4fctus',
    },
    PRICING: [
      {
        TITLE: "Free Plan",
        NEXT_CHECK: 10080,
        PAYMENT_LINK: '/'
      },
      {
        TITLE: "Standard Plan",
        NEXT_CHECK: 1440,
        PAYMENT_LINK: 'https://buy.stripe.com/test_6oEeYS6jR7lW0ZGbIK',
        PRODUCT_ID: 'prod_P5qkNbHmCg8WaU'
      },
      {
        TITLE: "Premium Plan",
        NEXT_CHECK: 60,
        PAYMENT_LINK: 'https://buy.stripe.com/test_fZe03Y0Zx5dOcIo9AD',
        PRODUCT_ID: 'prod_P5qkkpHCanCG72'
      },
    ],
    BILLING: {
      CUSTOMER_PORTAL: 'https://billing.stripe.com/p/login/test_6oEaIjasf0hhawEcMM',
    },
    HANDLE: {
      API_URL: 'https://zwqcudcw62.execute-api.us-east-1.amazonaws.com/DEV',
    }
  },
  production: {
    AWS: {
      REGION: 'us-east-1',
      USER_POOL_ID: 'us-east-1_WtFeUhvTV',
      USER_POOL_CLIENT_ID: '1s85t3l7vgh6o3oqu3ng3r8p9e',
    },
    PRICING: [
      {
        TITLE: "Free Plan",
        NEXT_CHECK: 10080,
        PAYMENT_LINK: '/'
      },
      {
        TITLE: "Standard Plan",
        NEXT_CHECK: 1440,
        PAYMENT_LINK: 'https://buy.stripe.com/5kAaFm27r4l52eQdQR',
        PRODUCT_ID: 'prod_OVtMj6VmgC9eTi'
      },
      {
        TITLE: "Premium Plan",
        NEXT_CHECK: 60,
        PAYMENT_LINK: 'https://buy.stripe.com/5kAaFm9zT6td1aM8ww',
        PRODUCT_ID: 'prod_OVtLIZybNakt7E'
      }
    ],
    BILLING: {
      CUSTOMER_PORTAL: 'https://billing.stripe.com/p/login/4gw7sR7KngYQdY49AA',
    },
    HANDLE: {
      API_URL: 'https://cbugd472ye.execute-api.us-east-1.amazonaws.com/PROD',
    }
  },
};
  
module.exports = process.env.NODE_ENV === 'production' ? config.production : config.development;
  