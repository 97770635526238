import {
    Box,
    Stack,
    HStack,
    Heading,
    Text,
    VStack,
    useColorModeValue,
    List,
    ListItem,
    ListIcon,
    Button,
} from '@chakra-ui/react'
import { FaCheckCircle } from 'react-icons/fa'
import config from '../../config'

const PricingBuckets = ({ toSignup, buttonText })=> {

    const toPricingRouteOrSignup = (pricingOption) => {
        if (toSignup) {
            window.location.href = '/signup/' + pricingOption;
        } else {
            try {
                window.location.href = config.PRICING[pricingOption].PAYMENT_LINK;
            } catch (error) {
                console.error(error);
            }
        }
    }

    return (
        <Box py={12}>
            <VStack spacing={2} textAlign="center">
                <Heading as="h1" fontSize="4xl">
                Plans that fit your need
                </Heading>
                <Text fontSize="lg" color={'gray.500'}>
                Start with 14-day free trial. No credit card needed. Cancel at anytime.
                </Text>
            </VStack>
        <Stack
            direction={{ base: 'column', md: 'row' }}
            textAlign="center"
            justify="center"
            spacing={{ base: 4, lg: 10 }}
            py={10}>
            <PriceWrapper>
                <Box py={4} px={12}>
                    <Text fontWeight="500" fontSize="2xl">
                    Free
                    </Text>
                    <HStack justifyContent="center">
                    <Text fontSize="3xl" fontWeight="600">
                        $
                    </Text>
                    <Text fontSize="5xl" fontWeight="900">
                        0
                    </Text>
                    <Text fontSize="3xl" color="gray.500">
                        /month
                    </Text>
                    </HStack>
                </Box>
                <VStack
                    bg={useColorModeValue('gray.50', 'gray.700')}
                    py={4}
                    borderBottomRadius={'xl'}>
                    <List spacing={3} textAlign="start" px={12}>
                    <ListItem>
                        <ListIcon as={FaCheckCircle} color="green.500" />
                        Monitor up to 10 handles
                    </ListItem>
                    <ListItem>
                        <ListIcon as={FaCheckCircle} color="green.500" />
                        Email notifications every week
                    </ListItem>
                    <ListItem>
                        <ListIcon as={FaCheckCircle} color="green.500" />
                        Checks Twitter/X
                    </ListItem>
                    </List>
                    <Box w="80%" pt={7}>
                        <Button w="full" colorScheme="blue" variant="outline" onClick={() => toPricingRouteOrSignup(0)}>
                            {buttonText}
                        </Button>
                    </Box>
                </VStack>
            </PriceWrapper>

            <PriceWrapper>
                <Box position="relative">
                    <Box
                    position="absolute"
                    top="-16px"
                    left="50%"
                    style={{ transform: 'translate(-50%)' }}>
                    <Text
                        textTransform="uppercase"
                        bg={useColorModeValue('blue.300', 'blue.700')}
                        px={3}
                        py={1}
                        color={useColorModeValue('gray.900', 'gray.300')}
                        fontSize="sm"
                        fontWeight="600"
                        rounded="xl">
                        Most Popular
                    </Text>
                    </Box>
                    <Box py={4} px={12}>
                    <Text fontWeight="500" fontSize="2xl">
                        Standard
                    </Text>
                    <HStack justifyContent="center">
                        <Text fontSize="3xl" fontWeight="600">
                        $
                        </Text>
                        <Text fontSize="5xl" fontWeight="900">
                        10
                        </Text>
                        <Text fontSize="3xl" color="gray.500">
                        /month
                        </Text>
                    </HStack>
                    </Box>
                    <VStack
                    bg={useColorModeValue('gray.50', 'gray.700')}
                    py={4}
                    borderBottomRadius={'xl'}>
                    <List spacing={3} textAlign="start" px={12}>
                        <ListItem>
                        <ListIcon as={FaCheckCircle} color="green.500" />
                        Monitor unlimited number of handles 
                        </ListItem>
                        <ListItem>
                        <ListIcon as={FaCheckCircle} color="green.500" />
                        Email notifications within hour of availability
                        </ListItem>
                        <ListItem>
                        <ListIcon as={FaCheckCircle} color="green.500" />
                        Checks Twitter/X
                        </ListItem>
                    </List>
                    <Box w="80%" pt={7}>
                        <Button w="full" colorScheme="blue" onClick={() => toPricingRouteOrSignup(1)}>
                            {buttonText}
                        </Button>
                    </Box>
                    </VStack>
                </Box>
            </PriceWrapper>
            <PriceWrapper>
                <Box py={4} px={12}>
                    <Text fontWeight="500" fontSize="2xl">
                    Premium
                    </Text>
                    <HStack justifyContent="center">
                    <Text fontSize="3xl" fontWeight="600">
                        $
                    </Text>
                    <Text fontSize="5xl" fontWeight="900">
                        20
                    </Text>
                    <Text fontSize="3xl" color="gray.500">
                        /month
                    </Text>
                    </HStack>
                </Box>
                <VStack
                    bg={useColorModeValue('gray.50', 'gray.700')}
                    py={4}
                    borderBottomRadius={'xl'}>
                    <List spacing={3} textAlign="start" px={12}>
                    <ListItem>
                        <ListIcon as={FaCheckCircle} color="green.500" />
                        Monitor unlimited number of handles
                    </ListItem>
                    <ListItem>
                        <ListIcon as={FaCheckCircle} color="green.500" />
                        Email notifications within hour of availability
                    </ListItem>
                    <ListItem>
                        <ListIcon as={FaCheckCircle} color="green.500" />
                        Checks Twitter/X
                    </ListItem>
                    <ListItem>
                        <ListIcon as={FaCheckCircle} color="green.500" />
                        Dedicated Support Channel
                    </ListItem>
                    </List>
                    <Box w="80%" pt={7}>
                        <Button w="full" colorScheme="blue" variant="outline"  onClick={() => toPricingRouteOrSignup(2)}>
                            {buttonText}
                        </Button>
                    </Box>
                </VStack>
            </PriceWrapper>
        </Stack>
        </Box>
    )
}

function PriceWrapper(props) {
    const { children } = props

    return (
        <Box
        mb={4}
        shadow="base"
        borderWidth="1px"
        alignSelf={{ base: 'center', lg: 'flex-start' }}
        borderColor={useColorModeValue('gray.200', 'gray.500')}
        borderRadius={'xl'}>
            {children}
        </Box>
    )
}

export default PricingBuckets;