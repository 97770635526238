import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  HStack,
  PinInput,
  PinInputField,
  Stack,
  InputGroup,
  InputRightElement,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { useState } from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { getUserPool, authenticate } from '../../utils/auth';
import { simulateClickOnEnter } from '../../utils/keyboard';

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const [resetLoading, setResetLoading] = useState(false);

  const resetToast = useToast();

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}>
      <Stack
        spacing={4}
        w={'full'}
        maxW={'md'}
        bg={useColorModeValue('white', 'gray.700')}
        rounded={'xl'}
        boxShadow={'lg'}
        p={6}
        my={12}>
        <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
          Enter new password
        </Heading>
        <FormControl id="email" isRequired>
          <FormLabel>Email address</FormLabel>
          <Input
            onChange={(event) => {
                setEmail(event.target.value)
            }}
            placeholder="your-email@example.com"
            _placeholder={{ color: 'gray.500' }}
            type="email"
          />
        </FormControl>
        <FormControl id="verificationCode" isRequired>
            <FormLabel>Confirmation Pin</FormLabel>
            <HStack>
              <PinInput otp onChange={(pinCode) => {setVerificationCode(pinCode)}} >
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
              </PinInput>
            </HStack>
        </FormControl>
        <FormControl id="password" isRequired>
          <FormLabel>New Password</FormLabel>
          <InputGroup>
            <Input id="passwordInput" type={showPassword ? 'text' : 'password'} onChange={(event) => {setNewPassword(event.target.value)}} onKeyDown={(event) => simulateClickOnEnter(event, "buttonResetPassword")} />
              <InputRightElement h={'full'}>
                <Button
                  variant={'ghost'}
                  onClick={() => setShowPassword((showPassword) => !showPassword)}>
                  {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                </Button>
              </InputRightElement>
            </InputGroup>
        </FormControl>
        <Stack spacing={6}>
          <Button
            id='buttonResetPassword'
            isLoading={resetLoading}
            loadingText="Submitting"
            onClick={() => {
              setResetLoading(true);
              passwordReset(email, verificationCode, newPassword)
                .then(result => {
                  // log the user in
                  authenticate(email, newPassword)
                    .then(() => {
                      setResetLoading(false);
                      resetToast({
                        title: `Password reset successful`,
                        status: "success",
                        isClosable: true,
                      });
                      setTimeout(() => {
                        window.location.href = "/";
                      }, 1500);
                    })
                    .catch(err => {
                      setResetLoading(false);
                      console.error(err);
                      resetToast({
                        title: err.message,
                        status: "error",
                        isClosable: true,
                      });
                    }
                  );
                })
                .catch(err => {
                  setResetLoading(false);
                  console.error(err);
                  resetToast({
                    title: err.message,
                    status: "error",
                    isClosable: true,
                  });
                }
              );
            }}
            bg={'blue.400'}
            color={'white'}
            _hover={{
              bg: 'blue.500',
            }}>
            Submit
          </Button>
        </Stack>
      </Stack>
    </Flex>
  )
}

function passwordReset(email, verificationCode, newPassword) {
  const userPool = getUserPool();

  return new Promise((resolve, reject) => {
      if (!email || !verificationCode || !newPassword) {
          reject(new Error("Please fill in all fields"));
          return;
      }

      const user = new CognitoUser({
          Username: email,
          Pool: userPool
      });

      user.confirmPassword(verificationCode, newPassword, {
          onSuccess: result => resolve(result),
          onFailure: err => reject(err)
      });
  });
}

export default ResetPassword;