function moveFocusOnEnter(event, nextId) {
    if (event.key === 'Enter') {
        document.getElementById(nextId).focus();
    }
}

function simulateClickOnEnter(event, clickId) {
    if (event.key === 'Enter') {
        document.getElementById(clickId).click();
    }
}

module.exports =  {moveFocusOnEnter, simulateClickOnEnter}