import '@aws-amplify/ui-react/styles.css';
import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Button,
    Heading,
    Text,
    useColorModeValue,
    useToast,
    Link,
} from '@chakra-ui/react'
import { useState } from 'react';
import { authenticate } from '../../utils/auth';
import { moveFocusOnEnter, simulateClickOnEnter } from "../../utils/keyboard"

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [buttonLoginLoading, setButtonLoginLoading] = useState(false);

  const loginErrorToast = useToast();

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6} w={'lg'}>
        <Stack align={'center'}>
          <Heading fontSize={'4xl'}>Sign in to your account</Heading>
          <Text fontSize={'lg'} color={'gray.600'}>
            and start tracking your favorite handles 🎉
          </Text>
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}>
          <Stack spacing={4}>
            <FormControl id="email">
              <FormLabel htmlFor='emailInput'>Email address</FormLabel>
              <Input id="emailInput" type="email" onChange={(event) => {setEmail(event.target.value)}} onKeyDown={(event) => {moveFocusOnEnter(event, "passwordInput")}}/>
            </FormControl>
            <FormControl id="password">
              <FormLabel htmlFor='passwordInput'>Password</FormLabel>
              <Input id="passwordInput" type="password" onChange={(event) => {setPassword(event.target.value)}} onKeyDown={(event) => {simulateClickOnEnter(event, "buttonLogin")}}/>
            </FormControl>
            <Stack spacing={4}>
              <Stack
                direction={{ base: 'column', sm: 'row' }}
                align={'start'}
                justify={'space-between'}>
                <Link color={'blue.400'} href="/forgot-password">Forgot password?</Link>
              </Stack>
              <Stack>
                <Button
                  isLoading={buttonLoginLoading}
                  loadingText="Signing in"
                  id="buttonLogin"
                  bg={'blue.400'}
                  color={'white'}
                  onClick={() => {
                      setButtonLoginLoading(true);
                      login(email, password, loginErrorToast, setButtonLoginLoading);
                  }}
                  _hover={{
                    bg: 'blue.500',
                  }}>
                  Sign in
                </Button>
                <Text align={'center'}>
                  Need an account? <Link color={'blue.400'} href="/signup" >Signup</Link>
                </Text>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}

function login(email, password, loginErrorToast, setButtonLoginLoading) {
    authenticate(email, password)
        .then(data => {
            setButtonLoginLoading(false);
            window.location.href = "/";
        }, err => {
            console.error(err);
            loginErrorToast({
                title: err.message,
                status: "error",
                isClosable: true,
            });
            setButtonLoginLoading(false);
        })
        .catch(err => {console.error(err);});
}

export default Login;
