import {
  Box,
  Container,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
  Button,
} from '@chakra-ui/react'
import TermsOfService from './TermsOfService'
import PrivacyPolicy from './PrivacyPolicy'

export default function SmallCentered() {
  const { isOpen: isPrivacyOpen, onOpen: onPrivacyOpen, onClose: onPrivacyClose } = useDisclosure()
  const { isOpen: isTermsOfServiceOpen, onOpen: onTermsOfServiceOpen, onClose: onTermsOfServiceClose } = useDisclosure()
  return (<>
    <Box
      bg={useColorModeValue('gray.50', 'gray.900')}
      color={useColorModeValue('gray.700', 'gray.200')}>
      <Container
        as={Stack}
        maxW={'6xl'}
        py={4}
        spacing={4}
        justify={'center'}
        align={'center'}>
        <div style={{display:"flex", alignItems:"center"}}>
            <img src="handleBlack.png" width={25} style={{margin:"3px", border: "2px solid #000", borderRadius:"5px"}} />
            <div style={{margin:"3px"}}><b>GetTheHandle</b></div>
        </div>
        <Stack direction={'row'} spacing={6}>
          <Button variant="link" onClick={onPrivacyOpen} colorScheme='black'>
            Privacy Policy
          </Button>
          <Button variant="link" onClick={onTermsOfServiceOpen} colorScheme='black'>
            Terms of Service
          </Button>
        </Stack>
      </Container>
      <Box
        borderTopWidth={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.700')}>
        <div style={{justifyContent:"center", display: "flex", padding: "20px"}}>
          © 2023 GetTheHandle. All rights reserved
        </div>
      </Box>
    </Box>
    <Modal
      isOpen={isPrivacyOpen}
      onClose={onPrivacyClose}
      size={'max'}
      scrollBehavior={'inside'}>
      <ModalOverlay />
        <ModalContent>
          <ModalHeader>Privacy Policy</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <PrivacyPolicy />
          </ModalBody>
          <ModalFooter>
              <Button variant='outline' onClick={onPrivacyClose}>Acknowledge</Button>
          </ModalFooter>
        </ModalContent>
    </Modal>
    <Modal
      isOpen={isTermsOfServiceOpen}
      onClose={onTermsOfServiceClose}
      size={'max'}
      scrollBehavior={'inside'}>
      <ModalOverlay />
        <ModalContent>
          <ModalHeader>Terms of Service</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TermsOfService />
          </ModalBody>
          <ModalFooter>
              <Button variant='outline' onClick={onTermsOfServiceClose}>Acknowledge</Button>
          </ModalFooter>
        </ModalContent>
    </Modal>
  </>)
}