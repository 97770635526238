import { useAuthContext } from "../../contexts/AuthContext";
import { refreshTokensIfNecessary } from "../../utils/auth";
import { QuestionOutlineIcon, MinusIcon, DeleteIcon, CalendarIcon } from "@chakra-ui/icons";
import { 
  Input,
  IconButton,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Card,
  CardBody,
  flexbox,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text,
  Switch,
  Spinner,
  Tooltip,
  Hide,
} from "@chakra-ui/react";
import { 
  FaFacebook,
  FaTiktok,
  FaInstagram,
  FaRegGem,
 } from 'react-icons/fa';
import { useEffect, useState } from 'react';
import deepEqual from 'deep-equal';
import HandleStatusTable from "../../components/tables/HandleStatusTable";
import "./Handles.css";
import { putHandles, getHandles } from "../../apis/handle";
import { utcTimeToDate, utcTimeDateIfNextDayTimeIfNot, utcTimePlusMinutes } from "../../utils/time";
import config from "../../config";


const getTimeToNextCheckAmount = (user) => {
  let next_check = config.PRICING[0].NEXT_CHECK;
  for (let i = 0; i < config.PRICING.length; i++) {
    if (user.signInUserSession.idToken.payload['custom:productId'] === config.PRICING[i].PRODUCT_ID) {
      next_check = config.PRICING[i].NEXT_CHECK;
    }
  }
  return next_check;
}

const Handles = () => {
  const { currentUser, setCurrentUser } = useAuthContext();
  let currentUserJwtToken = currentUser.signInUserSession.idToken.jwtToken;
  const [handleModalOpen, setHandleModalOpen] = useState(false);
  const [iconHover, setIconHover] = useState(null);
  const [deleteRowHover, setDeleteRowHover] = useState(false);
  const [platformsHover, setPlatformsHover] = useState(false);
  const [platformsModal, setPlatformsModal] = useState(false);
  const [modalEntryIndex, setModalEntryIndex] = useState(0);
  const [editingNewFlag, setEditingNewFlag] = useState(false);
  const [candidate, setCandidate] = useState(null);
  const [content, setContent] = useState([]);

  const initUserHandles = async () => {
    const response = await getHandles(currentUserJwtToken);
    // check that the response has items
    if (response?.Items) {
      // check that we get one response items in the response items list
      const responseItemsList = response.Items
      if (responseItemsList.length == 0) {
        return
      }
      if (responseItemsList.length != 1) {
        console.error("Expected 1 response item in the response items list, but got " + responseItemsList.length)
        return
      }
      const responseItem = responseItemsList[0]
      // check that the response item has a handles entry
      if (responseItem?.handles) {
        setContent(responseItem.handles)
      } else {
        console.error("Expected a handles entry in the response item, but got " + responseItem)
      }
    }
  }

  useEffect(() =>{
    initUserHandles();
  }, []);


  const tableEntryHover = {
    backgroundColor: "gray.100",
    cursor: "pointer",
  }

  const rowClick = (entryIndex, iconHover, deleteRowHover) => {
    if (iconHover) {
      return
    }
    if (deleteRowHover) {
      return
    }
    setModalEntryIndex(entryIndex)
    setHandleModalOpen(true)
  }

  const onHandleModalClose = async (index, value) => {
    setHandleModalOpen(false)
    let newContent = content
    if (typeof index == 'number') {
        if (value) {
          newContent[index] = value
        } else {
          newContent = content.filter((_, itemIndex) => itemIndex !== index)
        }
    }
    let putHandlesObject = {
      "handles": newContent,
    }
    // set the content as well as set it when it responds
    setContent(newContent)
    // refresh the token if necessary
    const refreshedUser = await refreshTokensIfNecessary(currentUser);
    setCurrentUser(refreshedUser);
    currentUserJwtToken = refreshedUser.signInUserSession.idToken.jwtToken;
    // make the put call
    let response = await putHandles(currentUserJwtToken, putHandlesObject);
    if (response.handles) {
      setContent(response.handles)
    }
    // set the editing new flag to false
    setEditingNewFlag(false)
  }

  const iconToggle = (iconKey, setFunction, candidate) => {
    const platform = iconKey.platform;
    const handle = iconKey.handle;

    if (candidate === null) {
      setFunction(
        content.map((item, _) =>
          item.handle === handle
            ? {
                ...item,
                platforms: {
                  ...item.platforms,
                  [platform]: {
                    ...item.platforms[platform],
                    enabled: !item.platforms[platform].enabled,
                  },
                },
              }
            : item
        )
      );
      return
    } else {
      setFunction(
        candidate.map((item, _) =>
          item.handle === handle
            ? {
                ...item,
                platforms: {
                  ...item.platforms,
                  [platform]: {
                    ...item.platforms[platform],
                    enabled: !item.platforms[platform].enabled,
                  },
                },
              }
            : item
        )
      );
    }
  };

  const modifyHandleCandidate = (index, handle, candidate) => {
    if (candidate === null) {
      setCandidate(
        content.map((item, itemIndex) =>
          itemIndex === index ? {
            ...item,
            handle: handle,
          } : item
        )
      )
    } else {
      setCandidate(
        candidate.map((item, itemIndex) =>
          itemIndex === index ? {
            ...item,
            handle: handle,
          } : item
        )
      )
    }
  }

  const addBlankContentItem = () => {
    const newItem = {
      handle: "",
      platforms: {
        "facebook": {
          available: false,
          enabled: true,
        },
        "instagram": {
          available: false,
          enabled: true,
        },
        "tiktok": {
          available: false,
          enabled: true,
        },
      },
      nextCheck: null,
      lastChecked: null,
    }
    setContent(
      content.concat(newItem)
    )
    return newItem
  }

  const platformIcon = (platform, color) => {
    if (platform === "facebook") {
      return <FaFacebook color={color}/>
    } else if (platform === "instagram") {
      return <FaInstagram color={color} />
    } else if (platform === "tiktok") {
      return <FaTiktok color={color} />
    }
  }

  const deleteRowIcon = (index) => {
    return (
      <IconButton 
        onMouseEnter={() => {setDeleteRowHover(true)}}
        onMouseLeave={() => {setDeleteRowHover(false)}}
        aria-label="Delete Row"
        icon={<DeleteIcon />}
        sx={{'&:hover': { backgroundColor: 'white', color: 'red', }}}
        isRound={false} onClick={() => {onHandleModalClose(index, null)}} />
    )
  }

  const entryHasEnabledPlatform = (entry) => {
    const enabledArr = Object.keys(entry.platforms).map((key, index) => {
      return entry.platforms[key].enabled
    })
    return enabledArr.includes(true)
  }

  const platformsStyle = {
    display:"flex",
    justifyContent:"flex-start",
    alignItems:"center",
  }
  if (platformsHover) {
    platformsStyle.color = "grey"
    platformsStyle.textDecoration = "underline"
    platformsStyle.cursor = "pointer"
  }
  
  return (
    <Stack spacing={4}>
      <Card>
        <CardBody>
          <TableContainer backgroundColor="white">
            <Table variant='simple'>
              <Thead>
                <Tr>
                  <Th>Handle</Th>
                  <Th>
                    <div style={platformsStyle} onMouseEnter={()=>setPlatformsHover(true)} onMouseLeave={()=>setPlatformsHover(false)} onClick={()=>{setPlatformsModal(true)}}>
                      Platforms
                      <QuestionOutlineIcon style={{margin:"0 2px"}}/>
                    </div>
                  </Th>
                    <Th className="smallScreenHide">
                      Last Checked
                    </Th>
                    <Th className="smallScreenHide">
                      Next Check
                    </Th>
                </Tr>
              </Thead>
              <Tbody>
                {
                  content.map((entry, outerIndex) => {
                    return (
                      <Tr key={outerIndex} _hover={tableEntryHover} onClick={() => rowClick(outerIndex, iconHover, deleteRowHover)}>
                        <Td style={{whiteSpace:"normal", wordBreak:"break-all", maxWidth:"150px"}}>
                          @{entry.handle}
                        </Td>
                        <Td>
                          <div style={{display:"flex", justifyContent:"space-between"}}>
                            <div key={outerIndex} style={{display:"flex"}}>
                              {Object.keys(entry.platforms).map((key, index) => {
                                const platform = key
                                const enabled = entry.platforms[key].enabled
                                const available = entry.platforms[key].available
                                const iconColor = enabled ? (available ? "green": "red") : "grey"
                                const iconKey = {
                                  handle: entry.handle,
                                  platform: platform,
                                }
                                let iconStyle = {
                                  display:"flex",
                                  justifyContent:"center",
                                  alignItems:"center",
                                  padding:"5px",
                                  backgroundColor:"#edf2f6",
                                  margin:"0 5px",
                                  borderRadius:"20%",
                                }
                                if (deepEqual(iconKey, iconHover)) {
                                  iconStyle["backgroundColor"] = "white"
                                }
                                return (
                                  <div key={index} style={iconStyle} onMouseEnter={()=>setIconHover(iconKey)} onMouseLeave={()=>{setIconHover(null)}} onClick={()=>{iconToggle(iconKey, setContent, null)}}>
                                    {platformIcon(platform, iconColor)}
                                  </div>
                                )
                              })}
                            </div>
                            <div className="smallScreenShow">
                              {deleteRowIcon(outerIndex)}
                            </div>
                          </div>
                        </Td>
                        <Td className="smallScreenHide">
                          <div style={{display:"flex", alignItems:"center"}}>
                            {entry.lastChecked === null ?
                              <MinusIcon style={{
                                margin:"10px",
                                color:"grey",
                              }} /> :
                              <CalendarIcon style={{
                                margin:"10px",
                              }} />
                            }
                            {utcTimeToDate(entry.lastChecked)}
                          </div>
                        </Td>
                        <Td className="smallScreenHide">
                          <div style={{display:"flex", justifyContent:"space-between"}}>
                            <div style={{display:"flex", alignItems:"center", width:"150px"}}>
                              {entryHasEnabledPlatform(entry) ? 
                                <Spinner
                                  thickness='4px'
                                  speed='1s'
                                  emptyColor='gray.200'
                                  color='blue.500'
                                  size='sm'
                                  style={{
                                    margin:"10px",
                                  }}
                                /> :
                                <MinusIcon color="grey" margin="10px" />
                              }
                              {entryHasEnabledPlatform(entry) ?
                                utcTimeDateIfNextDayTimeIfNot(utcTimePlusMinutes(entry.lastChecked, getTimeToNextCheckAmount(currentUser))) :
                                ""
                              }
                            </div>
                            <div>
                                {deleteRowIcon(outerIndex)}
                            </div>
                          </div>
                        </Td>
                      </Tr>
                    )
                  })
                }
              </Tbody>
            </Table>
          </TableContainer>
          <Button size="lg" width="100%" colorScheme="blue" margin="20px 0 0 0" onClick={()=>{
            const newItem = addBlankContentItem();
            setEditingNewFlag(true);
            rowClick(content.length, null);
          }}>
            + Add Handle
          </Button>
        </CardBody>
      </Card>
      <Modal isOpen={handleModalOpen} onClose={() => {
        setCandidate(null)
        editingNewFlag ? onHandleModalClose(modalEntryIndex, null) : onHandleModalClose(null, null)}
      } size="sm">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div style={{padding:"5px 0 25px 0"}}>
              <Input autoFocus={content[modalEntryIndex]?.handle ? false : true} size='lg' value={candidate==null ? content[modalEntryIndex]?.handle : candidate[modalEntryIndex]?.handle} onChange={(event) => {modifyHandleCandidate(modalEntryIndex, event.target.value, candidate)}} onKeyDown={(event) => {
                if (event.key ==="Enter") {
                  onHandleModalClose(modalEntryIndex, candidate===null ? content[modalEntryIndex] : candidate[modalEntryIndex])
                  setCandidate(null)
                }
              }}/>
            </div>
            <HandleStatusTable data={content[modalEntryIndex]} modalEntryIndex={modalEntryIndex} platformIconFunction={platformIcon} setCandidateFunction={setCandidate} candidate={candidate} iconToggle={iconToggle} newItem={editingNewFlag}/>
            <div style={{padding:"45px 15px 15px 15px"}}>
              <Button size="lg" width="100%" colorScheme="green" onClick={() => {
                onHandleModalClose(modalEntryIndex, candidate===null ? content[modalEntryIndex] : candidate[modalEntryIndex])
                setCandidate(null)
              }}>
                Save
              </Button>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={platformsModal} onClose={() => {setPlatformsModal(false)}} size="sm">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Icon Color Guide</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TableContainer>
              <Table variant='simple'>
              <TableCaption>* Clicking the icon will toggle Monitoring</TableCaption>
                <Tbody>
                  <Tr>
                    <Td>
                      <div style={{display:"flex", justifyContent:"center"}}>
                        <FaRegGem color="green" />
                      </div>
                    </Td>
                    <Td>
                      <ul>
                        <li>Monitored<sup>*</sup></li>
                        <li>Handle Available</li>
                      </ul>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <div style={{display:"flex", justifyContent:"center"}}>
                        <FaRegGem color="red" />
                        </div>
                    </Td>
                    <Td>
                      <ul>
                        <li>Monitored<sup>*</sup></li>
                        <li>Handle Unavailable</li>
                      </ul>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <div style={{display:"flex", justifyContent:"center"}}>
                        <FaRegGem color="grey" />
                      </div>
                    </Td>
                    <Td>
                      <ul>
                        <li>Not Monitored<sup>*</sup></li>
                      </ul>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Stack>
  );
}
  
export default Handles;
