import NavBar from '../../components/navigation/NavBar';
import CallToAction from './CallToAction';
import EasySteps from './EasySteps';
import Footer from '../../components/footers/Default';
import PricingBuckets from '../../components/pricing/PricingBuckets';
import {
    Box,
} from '@chakra-ui/react'

const Splash = () => {
    return (
        <>
            <NavBar />
            <CallToAction />
            <Box p={4} bg="gray.100" padding="3em">
                <EasySteps />
            </Box>
            <PricingBuckets toSignup={true} buttonText="Get Started" />
            <Footer />
        </>
    );
}

export default Splash;
