import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
} from "@chakra-ui/react";
const ConfirmationModal = ({isOpen, setIsOpen, confirmAction, title, description}) => {

    const onAccept = () => {
        confirmAction();
        setIsOpen(false);
    }
    const onDecline = () => {
        setIsOpen(false);
    }

    return (
        <Modal isOpen={isOpen} onClose={onDecline}>
            <ModalOverlay />
            <ModalContent>
            <ModalHeader>{title}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <div>
                    {description}
                </div>
            </ModalBody>

            <ModalFooter>
                <Button colorScheme='red' mr={3} onClick={onAccept}>
                    Confirm
                </Button>
                <Button variant='ghost' onClick={onDecline}>Cancel</Button>
            </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default ConfirmationModal;