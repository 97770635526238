import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import config from "../config";

function getUserPool() {
    return new CognitoUserPool({
        UserPoolId: config.AWS.USER_POOL_ID,
        ClientId: config.AWS.USER_POOL_CLIENT_ID
    });
}

function authenticate(email, password) {
    const userPool = getUserPool();

    return new Promise((resolve, reject) => {
        const user = new CognitoUser({
            Username: email,
            Pool: userPool
        });

        const authDetails = new AuthenticationDetails({
            Username: email,
            Password: password
        });

        user.authenticateUser(authDetails, {
            onSuccess: result => resolve(result),
            onFailure: err => reject(err)
        });
    });
}

async function getCurrentUser() {
    return new Promise((resolve, reject) => {
        const userPool = getUserPool();
        const cognitoUser = userPool.getCurrentUser();

        if (!cognitoUser) {
            reject(new Error("No user found"))
            return
        }

        cognitoUser.getSession((err, session) => {
            if (err) {
              reject(err)
              return
            }
            resolve(cognitoUser)
          })
    })
}

function refreshTokensIfNecessary(user) {
    if (user.signInUserSession.isValid()) {
        return user
    }

    return getCurrentUser();
}

function logout() {
    const userPool = getUserPool();
    const user = userPool.getCurrentUser();
    if (user) {
        user.signOut();
    }
}

async function deleteAccount(user) {
    if (user) {
        return await user.deleteUser((error) => {
            if (error) {
                console.error(error);
                return false;
            }
            return true;
        });
    } else {
        return false;
    }
}

export { authenticate, getCurrentUser, getUserPool, logout, refreshTokensIfNecessary, deleteAccount };