import { useState } from "react";
import SideBar from "../../components/navigation/SideBar";
import Profile from "./Profile";
import Handles from "./Handles";

const Home = () => {
  const [ focusedSubPage, setFocusedSubPage ] = useState(null);

  const getSubPage = () => {
    const showProfile = focusedSubPage === "Profile";
    const showHandles = !(showProfile);

    const getStyle = (show) => {
      return { display: show ? 'block' : 'none' };
    }

    return <>
      <div style={getStyle(showProfile)}>
        <Profile />
      </div>
      <div style={getStyle(showHandles)}>
        <Handles />
      </div>
    </>;
  };
  
  return (
    <SideBar menuSelect={setFocusedSubPage} >
      {getSubPage()}
    </SideBar>
  );
}
  
export default Home;
