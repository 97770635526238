import {
    Button,
} from "@chakra-ui/react";
import config from "../../config";
import {
    FiExternalLink,
  } from 'react-icons/fi'

const ManageSubscription = () => {
    return (
        <Button
        onClick={() => {
            window.open(config.BILLING.CUSTOMER_PORTAL);
        }}
        marginTop='3'
        rightIcon={<FiExternalLink />}
        size="md"
        bg={'blue.400'}
        color={'white'}
        _hover={{
            bg: 'blue.500',
        }}>
            Manage Subscription
        </Button>
    );
}

export default ManageSubscription;