import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  Link,
  useToast,
} from '@chakra-ui/react'
import { useState } from 'react'
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { useParams } from 'react-router-dom';
import { moveFocusOnEnter, simulateClickOnEnter } from "../../utils/keyboard"
import { getUserPool } from '../../utils/auth'
import config from '../../config'
import { authenticate } from '../../utils/auth';

const Signup = () => {
  const [signupLoading, setSignupLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const signupErrorToast = useToast();

  let onSuccessRoute = "/pricing"
  const { pricingOption } = useParams();
  if (pricingOption) {
    try {
      onSuccessRoute = config.PRICING[parseInt(pricingOption)].PAYMENT_LINK;
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6} w={'lg'}>
        <Stack align={'center'}>
          <Heading fontSize={'4xl'} textAlign={'center'}>
            Sign up
          </Heading>
          <Text fontSize={'lg'} color={'gray.600'}>
            and start tracking your favorite handles 🎉
          </Text>
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}>
          <Stack spacing={4}>
            <FormControl id="email" isRequired>
              <FormLabel htmlFor="emailInput">Email address</FormLabel>
              <Input id="emailInput" type="email" onChange={(event) => {setEmail(event.target.value)}} onKeyDown={(event) => {moveFocusOnEnter(event, "passwordInput")}}/>
            </FormControl>
            <FormControl id="password" isRequired>
              <FormLabel htmlFor="passwordInput">Password</FormLabel>
              <InputGroup>
              <Input id="passwordInput" type={showPassword ? 'text' : 'password'} onChange={(event) => {setPassword(event.target.value)}} onKeyDown={(event) => {simulateClickOnEnter(event, "buttonSignup")}}/>
                <InputRightElement h={'full'}>
                  <Button
                    variant={'ghost'}
                    onClick={() => setShowPassword((showPassword) => !showPassword)}>
                    {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <Stack pt={2}>
              <Button
                id="buttonSignup"
                isLoading={signupLoading}
                onClick={() => {
                    setSignupLoading(true);
                    signup(email, password)
                      .then(() => {
                          authenticate(email, password)
                            .then(() => {
                              setSignupLoading(false);
                              window.location.href = onSuccessRoute;
                            })
                            .catch((err) => {
                              setSignupLoading(false);
                              console.error(err);
                              signupErrorToast({
                                  title: err.message,
                                  status: "error",
                                  isClosable: true,
                              });
                              window.location.href = onSuccessRoute;
                            });
                      })
                      .catch((err) => {
                          setSignupLoading(false);
                          console.error(err);
                          signupErrorToast({
                              title: err.message,
                              status: "error",
                              isClosable: true,
                          });
                      });
                 }
                }
                loadingText="Signing up"
                size="lg"
                bg={'blue.400'}
                color={'white'}
                _hover={{
                  bg: 'blue.500',
                }}>
                Sign up
              </Button>
              <Text align={'center'}>
                Already a user? <Link color={'blue.400'} href="/login">Login</Link>
              </Text>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  )
}

function signup(email, password) {
    const userPool = getUserPool();
    return new Promise((resolve, reject) => {
        userPool.signUp(
            email,
            password,
            [{ Name: "email", Value: email }],
            null,
            (err, result) => {
              if (err) {
                reject(err)
                return
              }
              resolve(result.user)
            }
          );
    });
}

export default Signup;