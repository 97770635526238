import { 
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Stack,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Card,
    CardBody,
    flexbox,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Text,
    Switch,
    Tooltip,
  } from "@chakra-ui/react";
  import { QuestionOutlineIcon, CheckIcon, MinusIcon, CloseIcon } from "@chakra-ui/icons";


const HandleStatusTable = ({ data, modalEntryIndex, platformIconFunction, setCandidateFunction, candidate, iconToggle, newItem}) => {
    return (
        <TableContainer>
            <Table variant='simple' size="sm">
                <Thead>
                    <Tr>
                        <Th>Company</Th>
                        <Th style={{padding:"0 30px"}}>Monitor | Available</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    { data?.platforms &&
                    Object.keys(data.platforms).map((key, _) => {
                        const platform = key
                        const available = candidate===null ? data.platforms[key].available : candidate[modalEntryIndex].platforms[key].available
                        const enabled = candidate===null ? data.platforms[key].enabled : candidate[modalEntryIndex].platforms[key].enabled
                        const iconColor = (enabled && !newItem) ? (available ? "green" : "red") : "grey"
                        const iconKey = {
                            handle: data.handle,
                            platform: platform,
                        }
                        return (
                            <Tr key={platform}>
                                <Td>
                                    <div style={{display:"flex", justifyContent:"flex-start", alignItems:"center", padding:"4px 0"}}>
                                    <div style={{padding:"0 4px"}}>
                                        {platformIconFunction(platform, iconColor)}
                                    </div>
                                    <div style={{padding:"0 4px"}}>
                                        <Text>{platform}</Text>
                                    </div>
                                    </div>
                                </Td>
                                <Td>
                                    <div style={{display:"flex", justifyContent:"space-around", alignItems:"center", padding:"4px 0"}}>
                                    <div style={{padding:"0 4px"}}>
                                        <Switch size='lg' defaultChecked={enabled} onChange={()=>{(iconToggle(iconKey, setCandidateFunction, candidate))}}/>
                                    </div>
                                    <div style={{borderLeft:"1px solid grey", height:"50px"}}></div>
                                    <div style={{width:"70px"}}>
                                        {(enabled && !newItem) ? available ? <CheckIcon color="green" /> : <CloseIcon color="red" /> : <MinusIcon color="grey" />}
                                    </div>
                                    </div>
                                </Td>
                            </Tr>
                        )
                    })
                    }
                </Tbody>
            </Table>
        </TableContainer>
    )
}

export default HandleStatusTable;