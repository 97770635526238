import { useAuthContext } from "../../contexts/AuthContext";
import SignOut from "../../components/buttons/SignOut";
import DeleteAccount from "../../components/buttons/DeleteAccount";
import ManageSubscription from "../../components/buttons/ManageSubscription";
import config from "../../config";
import {
    Box,
    Heading,
    Input,
    Stack,
    StackDivider,
    Card,
    CardHeader,
    CardBody,
} from "@chakra-ui/react";

const Profile = () => {
  const { currentUser, setCurrentUser } = useAuthContext();

  // get the current subscription tier of the user
  let subscriptionTier = 'Free Plan';
  for (let i = 0; i < config.PRICING.length; i++) {
    if (currentUser.signInUserSession.idToken.payload['custom:productId'] === config.PRICING[i].PRODUCT_ID) {
        subscriptionTier = config.PRICING[i].TITLE;
    }
  }
  
  return (
    <Card>
        <CardHeader>
            <Heading size='md'>Profile</Heading>
        </CardHeader>

        <CardBody>
            <Stack divider={<StackDivider />} spacing='4'>
                <Box>
                    <Heading size='xs' textTransform='uppercase'>
                        Email
                    </Heading>
                    <Input readOnly marginTop='3' w='auto' variant="filled" value={currentUser.signInUserSession.idToken.payload.email}/>
                </Box>
                <Box>
                    <Heading size='xs' textTransform='uppercase'>
                        Subscription Tier
                    </Heading>
                    <Input readOnly marginTop='3' w='auto' variant="filled" value={subscriptionTier}/>
                </Box>
                <Box>
                    <Heading size='xs' textTransform='uppercase'>
                        BILLING
                    </Heading>
                    <ManageSubscription />
                </Box>
                <Box>
                    <Heading size='xs' textTransform='uppercase'>
                        Sign Out / Delete Account
                    </Heading>
                    <div style={{display:"flex"}}>
                        <div style={{padding:"5px"}}>
                            <SignOut />
                        </div>
                        <div style={{padding:"5px"}}>
                            <DeleteAccount />
                        </div>
                    </div>
                </Box>
            </Stack>
        </CardBody>
    </Card>
  );
}


  
export default Profile;
