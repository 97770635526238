export function utcTimeToDate(utcTime) {
    const date = new Date(utcTime);
    const localDateString = date.toLocaleString();
    return localDateString.split(',')[0];
}

export function utcTimePlusMinutes(utcTime, minutes) {
    const date = new Date(utcTime);
    date.setMinutes(date.getMinutes() + minutes);
    return date.toISOString();
}

export function utcTimeDateIfNextDayTimeIfNot(utcTime) {
    const date = new Date(utcTime);
    const currentDate = new Date();
    if (date.getDate() === currentDate.getDate()) {
        return date.toLocaleTimeString();
    }
    return date.toLocaleDateString();
}