import '@aws-amplify/ui-react/styles.css';
import {
    Flex,
    useColorModeValue,

} from '@chakra-ui/react'
import PricingBuckets from '../../components/pricing/PricingBuckets';

const Login = () => {

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}>
        <PricingBuckets toSignup={false} buttonText="Select Plan" />
    </Flex>
  );
}

export default Login;
