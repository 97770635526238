import React, { createContext, useContext, useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { ThreeDots } from "react-loader-spinner";
import { getCurrentUser } from "../utils/auth";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
    const [retrievingUser, setRetrievingUser] = useState(true);
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
    getCurrentUser()
        .then((user) => {
            setCurrentUser(user);
            setRetrievingUser(false);
        })
        .catch((err) => {
            console.error(err)
            setRetrievingUser(false);
        });
    }, []);

    if (retrievingUser) {
        return (
            <ThreeDots color="black" />
        );
    }

    if (!currentUser) {
        return <Navigate to="/splash" replace />;
    }

    return (
    <AuthContext.Provider value={{ currentUser, setCurrentUser }}>
        {children}
    </AuthContext.Provider>
    );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('AuthContext must be used within a AuthContextProvider');
  }

  return context;
};
