import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import Home from '../../pages/home/Home';
import Login from '../../pages/login/Login';
import Splash from '../../pages/splash/Splash';
import ForgotPassword from '../../pages/forgot_password/ForgotPassword';
import Signup from '../../pages/signup/Signup';
import Pricing from '../../pages/pricing/Pricing';
import ResetPassword from '../../pages/reset_password/ResetPassword';
import { AuthContextProvider } from '../../contexts/AuthContext';

const AppRouter = () => {
  return (
    <ChakraProvider>
      <Router>
        <Routes>
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/signup/:pricingOption?" element={<Signup />} />
          <Route path="/splash" element={<Splash />} />
          <Route path="/login" element={<Login />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/" element={<AuthContextProvider>{<Home />}</AuthContextProvider>} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
};

export default AppRouter;
