import {
  Button,
  FormControl,
  Flex,
  Heading,
  Input,
  Stack,
  Text,
  useToast,
  useColorModeValue,
} from '@chakra-ui/react'
import { useState } from 'react'
import { simulateClickOnEnter } from '../../utils/keyboard'
import { CognitoUser } from 'amazon-cognito-identity-js';
import { getUserPool } from '../../utils/auth';

export default function ForgotPasswordForm() {
  const [email, setEmail] = useState('');
  const [buttonResetLoading, setButtonResetLoading] = useState(false);

  const resetToast = useToast();

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}>
      <Stack
        spacing={4}
        w={'full'}
        maxW={'md'}
        bg={useColorModeValue('white', 'gray.700')}
        rounded={'xl'}
        boxShadow={'lg'}
        p={6}
        my={12}>
        <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
          Forgot your password?
        </Heading>
        <Text
          fontSize={{ base: 'sm', sm: 'md' }}
          color={useColorModeValue('gray.800', 'gray.400')}>
          You&apos;ll get an email with a reset link
        </Text>
        <FormControl id="email">
          <Input
            onChange={(event) => {
                setEmail(event.target.value)
            }}
            onKeyDown={(event) => {
                simulateClickOnEnter(event, 'buttonReset')
            }}
            placeholder="your-email@example.com"
            _placeholder={{ color: 'gray.500' }}
            type="email"
          />
        </FormControl>
        <Stack spacing={6}>
          <Button
            isLoading={buttonResetLoading}
            loadingText="Sending email..."
            id="buttonReset"
            onClick={() => {
                setButtonResetLoading(true);
                forgotPassword(email)
                    .then(data => {
                        setButtonResetLoading(false);
                        resetToast({
                            title: `Reset email sent to ${email}`,
                            status: "success",
                            isClosable: true,
                        });
                        setTimeout(() => {
                            window.location.href = "/reset-password";
                        }, 1500);
                    })
                    .catch(err => {
                        setButtonResetLoading(false);
                        console.error(err.message);
                        resetToast({
                            title: err.message,
                            status: "error",
                            isClosable: true,
                        });
                    });
            }}
            bg={'blue.400'}
            color={'white'}
            _hover={{
              bg: 'blue.500',
            }}>
            Request Reset
          </Button>
        </Stack>
      </Stack>
    </Flex>
  )
}

function forgotPassword(email) {
    const userPool = getUserPool();

    return new Promise((resolve, reject) => {
        const user = new CognitoUser({
            Username: email,
            Pool: userPool
        });

        user.forgotPassword({
            onSuccess: result => resolve(result),
            onFailure: err => reject(err)
        });
    });
}